import { LabelColorIcon } from '~/features/labels/components/LabelColorIcon'
import { cn } from '~/lib/utils'

import { formatRecordTitle } from '../utils'

export function RecordTitle({
  className,
  ...props
}: Parameters<typeof formatRecordTitle>[0] & { className?: string }) {
  const title = formatRecordTitle(props)
  return (
    <span className={cn('inline-flex items-center gap-1', className)}>
      <LabelColorIcon label={props.label} />
      {title}
    </span>
  )
}
