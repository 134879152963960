import { useStore } from '@nanostores/react'

import { RecordSheet } from '~/features/projects/components/RecordSheet'
import { RecordsHeader } from '~/features/projects/components/RecordsHeader'
import { RecordsTable } from '~/features/records/components/RecordsTable'

import {
  $currentProjectRecordsLimit,
  $currentProjectRecordsSkip,
  $filteredRecords,
  decrementRecordsPage,
  incrementRecordsPage
} from '../stores/current-project'
import { $currentProjectVisibleFields } from '../stores/hidden-fields'
import { $sheetRecordId } from '../stores/id'

function View() {
  const {
    data: records,
    loading: loadingRecords,
    total = 0
  } = useStore($filteredRecords)
  const { data: fields, loading: loadingFields } = useStore(
    $currentProjectVisibleFields
  )

  const skip = useStore($currentProjectRecordsSkip)

  const limit = useStore($currentProjectRecordsLimit)

  const loading = loadingRecords || loadingFields

  // const view = useStore($recordView)

  return (
    <RecordsTable
      className="w-full"
      fields={fields}
      limit={limit}
      loading={loading}
      onNext={incrementRecordsPage}
      onPrev={decrementRecordsPage}
      onRecordClick={(record) => $sheetRecordId.set(record.__id)}
      records={records}
      skip={skip}
      total={total}
    />
  )

  // switch (view) {
  //   case 'table':
  //     return (
  //       <RecordsTable
  //         className="w-full pt-5"
  //         fields={fields ?? []}
  //         loading={loading}
  //         records={records}
  //       />
  //     )

  //   case 'graph':
  //     return <RecordsGraphView records={records} />

  //   default:
  //     return null
  // }
}

export function ProjectRecords() {
  return (
    <>
      <RecordsHeader />
      <View />
      <RecordSheet />
    </>
  )
}
