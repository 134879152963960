import { useStore } from '@nanostores/react'

import type { WithProjectID } from '~/features/projects/types'

import { PageHeader, PageTitle } from '~/elements/PageHeader'
import { FilesTable } from '~/features/files/components/FilesTable'
import { $currentProjectFiles } from '~/features/projects/stores/current-project'

export function ProjectFiles({ projectId }: WithProjectID) {
  const { data: files, loading } = useStore($currentProjectFiles)

  return (
    <>
      <PageHeader>
        <PageTitle>Files</PageTitle>
      </PageHeader>
      <FilesTable files={files} loading={loading} projectId={projectId} />
    </>
  )
}
