import { useStore } from '@nanostores/react'

import { WHITE_LABEL } from '~/config'
import { RootLayout } from '~/layout/RootLayout'
import {
  $router,
  isProjectPage,
  isProjectRecordPage,
  isProtectedRoute
} from '~/lib/router'
import { NotFoundPage } from '~/pages/404'
import { PasswordRecoveryPage } from '~/pages/forgot-password'
import { NewProjectPage } from '~/pages/project/new'
import { ProfilePage } from '~/pages/profile.tsx'
import { SignInPage } from '~/pages/signin'
import { SignUpPage } from '~/pages/signup'
import { WorkspaceBillingPage } from '~/pages/workspace/billing'
import { WorkspaceProjectsPage } from '~/pages/workspace/projects'
import { WorkspaceSettingsPage } from '~/pages/workspace/settings'

import { Toaster } from './elements/Toast'
import { LiveChat } from './features/auth/components/LiveChat'
import { ProjectLayout } from './layout/ProjectLayout'
import { ProjectRecordLayout } from './layout/ProjectRecordLayout'
import { AuthGoogle } from './pages/auth/google'
import { OauthPage } from '~/pages/auth/oauth'

function PublicRoutes() {
  const page = useStore($router)

  switch (page?.route) {
    case 'signin':
      return <SignInPage />
    case 'signup':
      return <SignUpPage />
    case 'passwordRecovery':
      return <PasswordRecoveryPage />
    case 'googleAuth':
      return <AuthGoogle />
    case 'oauth':
      return <OauthPage />
    default:
      return <NotFoundPage />
  }
}

function ProtectedRoutes() {
  const page = useStore($router)

  if (isProjectRecordPage(page)) {
    return <ProjectRecordLayout />
  }

  if (isProjectPage(page)) {
    return <ProjectLayout />
  }

  switch (page?.route) {
    case 'profile':
      return <ProfilePage />
    case 'workspaceSettings':
      return <WorkspaceSettingsPage />
    case 'workspaceBilling':
      if (WHITE_LABEL) {
        return null
      }
      return <WorkspaceBillingPage />
    case 'projects':
    case 'home':
      return <WorkspaceProjectsPage />
    case 'newProject':
      return <NewProjectPage />
    // case 'newWorkspace':
    //   return <NewWorkspacePage />
    default:
      return <NotFoundPage />
  }
}

function Routes() {
  const page = useStore($router)

  if (!page) {
    return <NotFoundPage />
  }

  if (isProtectedRoute(page.route)) {
    return (
      <RootLayout>
        <ProtectedRoutes />
      </RootLayout>
    )
  }

  return <PublicRoutes />
}

function ProductionScripts() {
  if (!import.meta.env.PROD || WHITE_LABEL) {
    return null
  }

  return (
    <>
      <LiveChat />
    </>
  )
}

export function App() {
  return (
    <>
      <Routes />

      <Toaster />

      <ProductionScripts />
    </>
  )
}
