import { UNLABELED } from '../labels/constants'

export const formatRecordTitle = ({
  label = UNLABELED,
  id
}: {
  id: string
  label?: string
}) => {
  const labelText = label === UNLABELED ? 'Unlabeled (NULL)' : label

  return `${labelText} ${id?.slice(0, 2)}..${id?.slice(-2)}`
}
