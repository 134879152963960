import { useState } from 'react'
import { BASE_URL } from '~/config'
import { Button } from '~/elements/Button'
import { DialogLoadingOverlay } from '~/elements/Dialog'
import { getRoutePath } from '~/lib/router'

const GITHUB_URL = `${BASE_URL}/api/v1/auth/github?redirectUrl=${
  window.location.origin
}${getRoutePath('oauth')}`

export function GithHubButton() {
  const [loading, setLoading] = useState(false)

  return (
    <>
      <Button
        as="a"
        href={GITHUB_URL}
        size="large"
        variant="primary"
        onClick={() => setLoading(true)}
      >
        Continue With GitHub
      </Button>

      {loading && <DialogLoadingOverlay />}
    </>
  )
}
