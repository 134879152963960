import { useStore } from '@nanostores/react'
import { Maximize, Trash, X } from 'lucide-react'

import { IconButton } from '~/elements/IconButton'
import { Kbd } from '~/elements/Kbd'
import { PageHeader, PageTitle } from '~/elements/PageHeader'
import { Close, Sheet } from '~/elements/Sheet'
import { Tab, Tabs, TabsContent, TabsList } from '~/elements/Tabs'
import { Tooltip } from '~/elements/Tooltip'
import { RecordTitle } from '~/features/records/components/RecordTitle'
import { deleteRecord } from '~/features/records/stores/mutations'
import { useHotkeys } from '~/hooks/useHotkeys'
import { getRoutePath, openRoute } from '~/lib/router'

import {
  $currentRecord,
  $currentRelatedRecords
} from '../stores/current-record'
import { $currentProjectId, $sheetRecordId } from '../stores/id'
import { RecordDataTab } from './RecordDataTab'
import { RelatedRecordsTab } from './RelatedRecordsTab.tsx'
import { ERecordSheetTabs } from '~/features/projects/types.ts'

const tabs: ERecordSheetTabs[] = [
  ERecordSheetTabs.data,
  // ERecordSheetTabs.api,
  ERecordSheetTabs.relations
]

export function RecordSheet() {
  const id = useStore($sheetRecordId)
  const { data: record } = useStore($currentRecord)
  const { data: relations } = useStore($currentRelatedRecords)
  const { mutate } = useStore(deleteRecord)
  const projectId = useStore($currentProjectId)

  useHotkeys({
    f: () =>
      id && record
        ? openRoute('projectRecord', {
            recordId: record.__id,
            id: projectId as string
          })
        : undefined
  })

  return (
    <Sheet
      onOpenChange={(open) => {
        if (!open) {
          $sheetRecordId.set(undefined)
        }
      }}
      open={id !== undefined}
    >
      <PageHeader className="sticky top-0 z-40 justify-start gap-5 bg-fill2 px-5 py-3">
        <Close asChild>
          <IconButton aria-label="close" variant="ghost">
            <X />
          </IconButton>
        </Close>

        <PageTitle className="flex-1 truncate text-xl">
          {record && <RecordTitle id={record.__id} label={record.__label} />}
        </PageTitle>

        {record && (
          <Close asChild>
            <IconButton
              aria-label="Delete Record"
              onClick={() => mutate({ id: record.__id })}
              variant="ghost"
            >
              <Trash />
            </IconButton>
          </Close>
        )}
        <Tooltip
          trigger={
            <IconButton
              href={
                !record
                  ? undefined
                  : getRoutePath('projectRecord', {
                      recordId: record.__id,
                      id: projectId as string
                    })
              }
              aria-label="Open Record"
              as="a"
            >
              <Maximize />
            </IconButton>
          }
          side="left"
        >
          <Kbd code="F">Fullscreen</Kbd>
        </Tooltip>
      </PageHeader>

      <Tabs defaultValue={ERecordSheetTabs.data}>
        <TabsList className="w-full border-b bg-fill2 px-5">
          {tabs
            .filter((t) => (!relations ? t !== ERecordSheetTabs.relations : t))
            .map((t) => (
              <Tab key={t} value={t}>
                {t}
              </Tab>
            ))}
        </TabsList>

        <TabsContent className="m-5" value={ERecordSheetTabs.data}>
          <RecordDataTab />
        </TabsContent>
        <TabsContent value={ERecordSheetTabs.relations}>
          <RelatedRecordsTab />
        </TabsContent>
      </Tabs>
    </Sheet>
  )
}
