import type { WithProjectID } from '../projects/types'
import type { File } from './types'

export const getFileUrl = ({
  type,
  id,
  projectId
}: Pick<File, 'id' | 'type'> & WithProjectID) =>
  `https://${projectId}.s3.${
    import.meta.env.FILE_AWS_REGION
  }.amazonaws.com/${id}/${id}.${type}`
