import { FileAudio, File as FileIcon } from 'lucide-react'

import type { THeadCellProps } from '~/elements/Table'
import type { WithProjectID } from '~/features/projects/types'

import { NothingFound } from '~/elements/NothingFound'
import { Skeleton } from '~/elements/Skeleton'
import { DataCell, HeadCell, TableRow } from '~/elements/Table'
import { cn, range } from '~/lib/utils'

import type { File } from '../types'

import { getFileUrl } from '../utils'

function FileRowName({
  children,
  className,
  ...props
}: TPolymorphicComponentProps<'div'>) {
  return (
    <div
      className={cn(
        'text-content-secondary inline-flex shrink-0 items-center gap-1.5',
        className
      )}
      {...props}
    >
      {/* // icon */}
      {children}
    </div>
  )
}

function FileHeadCell({ children, ...props }: THeadCellProps) {
  // const sort = useStore(recordsSort)
  // const sort = `name,asc`

  // const [sortField, sortDirection] = sort.split(',') as [string, SortDirection]

  return (
    <HeadCell
      {...props}
      // sortable
      // sortActive={sortField === prop.name}
      // sortField={sortField}
      // sortDirection={sortDirection}
      // onClick={() => {
      //   setRecordsSort(prop.name)
      // }}
    >
      <FileRowName>{children}</FileRowName>
    </HeadCell>
  )
}

function ExtensionIcon({ type }: Pick<File, 'type'>) {
  const Icon = (() => {
    switch (type) {
      case 'mp3':
        return FileAudio

      default:
        return FileIcon
    }
  })()

  return (
    <div className="bg-accent-pink text-base-white grid h-[32px] w-[32px] place-items-center rounded-lg">
      <Icon size={22} />
    </div>
  )
}

function FileRowSkeleton({ cols = 2 }: { cols: number }) {
  return (
    <TableRow>
      <DataCell>
        <Skeleton enabled>
          <ExtensionIcon type="webp" />
        </Skeleton>
      </DataCell>
      {range(cols).map((_, idx) => (
        <DataCell key={idx}>
          <Skeleton enabled>Loading...</Skeleton>
        </DataCell>
      ))}
    </TableRow>
  )
}

export function FilesTable({
  className,
  files,
  loading,
  projectId,
  ...props
}: TPolymorphicComponentProps<
  'div',
  {
    files?: File[]
    loading?: boolean
  } & Partial<WithProjectID>
>) {
  if (!loading && files && files?.length < 1) {
    return <NothingFound className="m-5 items-center justify-start" />
  }

  return (
    <div className={cn('overflow-auto', className)} {...props}>
      <table className="w-full">
        <thead>
          <tr>
            <HeadCell style={{ width: 32 }} />
            <FileHeadCell>Name</FileHeadCell>
            <FileHeadCell>Extension</FileHeadCell>
          </tr>
        </thead>
        <tbody>
          {loading
            ? range(3).map((_, idx) => <FileRowSkeleton cols={2} key={idx} />)
            : files?.map((file) => {
                const fileUrl = projectId
                  ? getFileUrl({ ...file, projectId })
                  : undefined
                return (
                  <TableRow
                    className="cursor-pointer"
                    key={file.id}
                    tabIndex={0}
                  >
                    <DataCell className="text-content-secondary">
                      <ExtensionIcon type={file.type} />
                    </DataCell>
                    <DataCell>
                      <a
                        className="border-b-2"
                        href={fileUrl}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {file.displayName}
                      </a>
                    </DataCell>
                    <DataCell>
                      <div className="bg-accent-pink text-base-white inline-flex rounded-md px-2 py-0.5 text-xs">
                        .{file.type}
                      </div>
                    </DataCell>
                  </TableRow>
                )
              })}
        </tbody>
      </table>
    </div>
  )
}
