import type { ReactNode } from 'react'

import { Logo } from '~/elements/Logo'
import { cn } from '~/lib/utils'

export function AuthLayout({
  children,
  className,
  title,
  ...props
}: TPolymorphicComponentProps<'div', { title?: ReactNode }>) {
  return (
    <div
      className={cn(
        className,
        'mx-auto flex min-h-screen flex-col items-center justify-start gap-3 p-5 sm:justify-center sm:gap-5'
      )}
      {...props}
    >
      <Logo />
      {title ? (
        <h1 className="mb-5 text-2xl font-bold leading-tight tracking-tight">
          {title}
        </h1>
      ) : null}
      <main className="flex w-full  max-w-lg flex-col gap-5 ">{children}</main>
    </div>
  )
}
