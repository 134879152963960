import { BASE_URL } from '~/config'
import { Button } from '~/elements/Button'

const GOOGLE_URL = `${BASE_URL}/api/v1/auth/google?redirectUrl=${window.location.origin}/auth/google`

export function GoogleButton() {
  return (
    <Button as="a" href={GOOGLE_URL} size="large" variant="accent">
      Continue With Google
    </Button>
  )
}
